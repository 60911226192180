<template>
    <div class="project">
        <div class="intro grid">
            <h1>{{$t('allUnited.title')}}</h1>
            <div class="cell">
                <p>{{$t('allUnited.intro')}}</p>
            </div>
            <div class="cell">
                <dl>
                    <dt>{{$t('project.client')}}</dt>
                    <dd>{{$t('allUnited.client')}}</dd>
                    <dt>{{$t('project.role')}}</dt>
                    <dd>{{$t('allUnited.role')}}</dd>
                    <dt>{{$t('project.timeframe')}}</dt>
                    <dd>2018 - 2020</dd>
                    <!-- <dt>{{$t('project.prototype')}}</dt>
                    <dd><a href="https://www.sketch.com/s/8e0e4fa7-60b8-4d55-ad65-c6fd0f0ae36e/v/Myx7Jw" class="button" target="_blank">All United Sketch prototype</a></dd> -->
                </dl>
            </div>
        </div>
        <div class="case">
            <div class="layout">
                <div class="layout__item layout__item--12">
                    <img src="@/assets/images/cases/allunited/Home.jpg" srcset="@/assets/images/cases/allunited/Home@2x.jpg 2x" alt="All United Clubsoftware homepage">
                    <p class="caption">{{$t('allUnited.coverImage')}}</p>
                </div>
                <div class="layout__item layout__item--9">
                    <img src="@/assets/images/cases/allunited/contacten.jpg" srcset="@/assets/images/cases/allunited/contacten@2x.jpg 2x" alt="All United Clubsoftware homepage">
                </div>
                <div class="layout__item layout__item--3">
                    <p class="caption">{{$t('allUnited.contacts')}}</p>
                </div>
            </div>
            <div class="layout layout--row-reverse">
                <div class="layout__item layout__item--9">
                    <img src="@/assets/images/cases/allunited/factuur.jpg" srcset="@/assets/images/cases/allunited/factuur@2x.jpg 2x" alt="All United Clubsoftware homepage">
                </div>
                <div class="layout__item layout__item--3">
                    <p class="caption">{{$t('allUnited.factuur')}}</p>
                </div>
            </div>
        </div>
        <OtherCases prev-route="/projects/fast-fluid" prev-title="fastFluid" next-route="/projects/cbr-rijdata" next-title="CbrRijdata" />
    </div>
</template>
<script>
import OtherCases from '@/components/OtherCases.vue'

export default {
    components: {
        OtherCases
    }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/_Project.scss';
</style>